<template>
  <section id="FormNewsletter">
    <Wrap class="md">
      <div class="titleContainer">
        <h2>
          {{ $t('title') }}
        </h2>
      </div>
      <FormValidation
        v-if="!hasSent"
        novalidate
        @submit="send">
        <Row class="email-row">
          <Column :sm="6">
            <Field
              autocomplete="email"
              :label="$t('email')"
              required
              rules="email"
              type="email"
              :value.sync="contact.email" />
          </Column>
        </Row>
        <div class="consentArea">
          <FieldConsent :value.sync="contact.hasAcceptedPolicy" />
        </div>
        <div class="submitArea">
          <Btn class="md" :isLoading="isSending" type="submit">
            {{ $t('send') }}
          </Btn>
        </div>
      </FormValidation>
      <Notice v-if="hasSent && message" :class="message.theme">
        {{ message.text }}
      </Notice>
    </Wrap>
  </section>
</template>

<script>
import { actions, getters } from 'views/utils'
import Message from 'views/mixins/Message'
import { isProduction } from '../../../../config'
import { softThrow } from '../../../../bugsnag'

export default {
  mixins: [Message],
  data() {
    return {
      contact: this.$store.getters.contactFactory(),
      isSending: false,
      hasSent: false,
    }
  },
  computed: {
    ...getters('isDesktop', 'referrers'),
  },
  methods: {
    async send() {
      try {
        this.isSending = true
        if (isProduction) {
          const recaptcha = await this.$recaptchaToken('contact')
          await this.CREATE_CONTACT({
            ...this.contact,
            phone: '00000000',
            leadFormName: 'homepage-newsletter',
            metaReferrers: this.referrers,
            timezone: ((new Date()).getTimezoneOffset() / 60),
            recaptcha,
          })

          // this.$gtag.event('Siųsti', {
          //   event_category: 'Forma',
          //   event_label: 'PRIVATIEMS',
          // })
          this.$gtag.event('success', {
            phoneNumber: '',
            emailAddress: this.contact.email,
          })

          this.$analytics.fbq.event('Lead')
        }

        this.hasSent = true
        this.success(this.$t('success'))
      } catch (error) {
        error.body = this.contact
        softThrow(error)
      } finally {
        this.isSending = false
      }
    },
    ...actions('CREATE_CONTACT'),
  },
}
</script>

<style lang="scss">
#FormNewsletter {
  background-color: $white;

  .titleContainer {
    margin: 0 0 4rem;
    text-align: center;
  }

  .email-row {
    justify-content: center;
  }

  .Column {
    margin-top: 1rem;
    @include md {
      margin-top: unset;
    }
  }

  .consentArea {
    text-align: center;
  }

  .submitArea {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }

  .Field-cont {
    font-size: $h5;
    line-height: 2rem;
    margin-bottom: 2rem;
    position: relative;
    @include md {
      font-size: $h5;
      line-height: 2.5rem;
      margin-bottom: 4rem;
    }

    &.active {
      label {
        font-size: $h6;
        top: -2rem;
      }
    }
  }

  .FieldConsent {
    &.active {
      label {
        font-size: $h6;
        top: 0;
      }
    }
  }

  .Field-cont label,
  label.field-label {
    @include trans;
    color: inherit;
    display: block;
    font-size: $h7;
    font-weight: $semibold;
    left: 0.5rem;
    line-height: 1rem;
    pointer-events: none;
    position: absolute;
    top: 0.25rem;
    @include md {
      line-height: inherit;
    }
  }

  .FieldConsent label {
    position: relative;
    top: 0;
    transform: unset;
    transition: unset;
  }

  .checkbox {
    label {
      pointer-events: unset;
    }
  }

  .Field textarea {
    height: 3.1rem;
    min-height: 3.1rem;
  }
}

</style>

<i18n>
{
  "lt": {
    "title": "Prenumeruokite naujienlaiškį!",
    "email": "El. Paštas",
    "name": "Vardas arba įmonės pavadinimas",
    "phone": "Telefono numeris",
    "message": "Žinutė",
    "send": "Prenumeruoti",
    "success": "Dėkojame! Susisieksime."
  }
}
</i18n>
