<template>
  <section class="SectionIconList" :class="background">
    <Wrap :class="size">
      <Headline v-if="headline">
        {{ headline }}
      </Headline>
      <h2 v-if="title" class="title">
        {{ title }}
      </h2>
      <Row class="items">
        <Column v-for="item in items" :key="item.icon" :lg="~~(12 / itemsPerLine)">
          <div class="item" :class="[alignClass, `per-line-${itemsPerLine}`]">
            <Row>
              <Column class="icon" :xs="columnSize">
                <Icon :name="item.icon" />
              </Column>
              <Column class="content" :xs="columnSize">
                <h3 class="label">
                  {{ item.title }}
                </h3>
                <p>{{ item.content }}</p>
              </Column>
            </Row>
          </div>
        </Column>
      </Row>
    </Wrap>
  </section>
</template>

<script>
import { getters } from '../utils'

export default {
  props: {
    align: { type: String, default: 'center' },
    background: { type: String, default: 'white' },
    headline: String,
    items: Array,
    itemsPerLine: { type: Number, default: 1 },
    title: String,
    size: { type: String, default: 'lg' },
  },
  computed: {
    alignClass() {
      return this.isCentered ? 'center' : 'left'
    },
    columnSize() {
      return this.isCentered ? 12 : 'auto'
    },
    isCentered() {
      return this.align === 'center' || !this.screenSizes.includes('lg')
    },
    ...getters('screenSizes'),
  },
}
</script>

<style lang="scss">
.SectionIconList {
  overflow: hidden;

  h2.title {
    font-size: $h3;
    text-align: center;
  }

  .Row.items {
    justify-content: center;
    margin-top: 5rem;
  }

  .item {
    flex-direction: column;
    margin: 0 auto 4rem;
    max-width: 32rem;
    @include md {
      margin: 0 0 3rem;
      max-width: none;
    }

    .label {
      color: $main;
      font-size: $h7;
      font-weight: $bold;
      margin: 0 0 1rem;
      text-transform: uppercase;
    }

    .Column.icon {
      text-align: center;

      .Icon {
        color: $grey;
        font-size: 6rem;
      }
    }

    .Column.content {
      text-align: inherit;
    }

    // alignment
    &.center {
      margin: 0 auto 5rem;
      max-width: 40rem;
      text-align: center;

      .label {
        margin-top: 1rem;
      }
    }

    &.left {
      font-size: $h6;
      padding: 1rem;
      text-align: left;

      > .Row {
        flex-wrap: nowrap;
      }

      .Column.content {
        flex: auto;
      }

      &.per-line-3 {
        .label {
          margin-bottom: 0;
        }
      }
    }

    &.left:not(.per-line-3) {
      .Column.icon {
        min-width: 10rem;
      }
    }
  }
}
</style>
