<template>
  <Splitter class="SectionSplit" :left="5" :right="7">
    <Pic
      v-if="src"
      slot="left"
      :alt="alt"
      class="image"
      :formats="formats"
      :loading="loading"
      :src="src" />
    <div slot="right" class="body">
      <Wrap>
        <Headline v-if="headline">
          {{ headline }}
        </Headline>
        <slot />
      </Wrap>
    </div>
  </Splitter>
</template>

<script>
import Splitter from './Splitter'

export default {
  components: { Splitter },
  props: {
    alt: {
      type: String,
      default: '',
    },
    formats: {
      type: Array,
      default: () => ['webp'],
    },
    headline: String,
    loading: String,
    src: String,
  },
}
</script>

<style lang="scss">
.SectionSplit {
  flex-direction: column;
  min-height: 40vh;
  @include md {
    flex-direction: row;
  }

  .Headline {
    margin-top: 0;
    @include md {
      margin-top: 1rem;
    }
  }

  .image {
    display: block;
    height: 100%;

    > img {
      height: 100%;
      left: 0;
      object-fit: cover;
      top: 0;
      width: 100%;
      @include lg {
        max-height: none;
        position: absolute;
      }
    }
  }

  .body {
    padding: 3rem 0;

    .Wrap {
      padding: 0 1rem;
      @include md {
        padding: 0 2rem;
      }

      > h3 {
        color: $black-true;
        font-size: $h35;
        margin-bottom: 2rem;
        @include md {
          font-size: $h3;
        }
      }

      > h4 {
        color: $black-true;
        font-size: $h4;
        margin-bottom: 2rem;
        @include md {
          font-size: $h35;
        }
      }

      p {
        margin-bottom: 2rem;
        opacity: 0.7;
      }

      ul {
        margin-left: 1.25rem;
      }
    }
  }

  &.full-image {
    .image {
      @include md {
        position: static;
      }
    }
  }
}
</style>
