<template>
  <section id="SectionSolarForEveryone">
    <h2>{{ $t('title') }}</h2>
    <Wrap class="container">
      <div v-for="type in types" :key="type.label" class="plant-type">
        <Row class="no-gutters">
          <Column class="image" :sm="5">
            <Pic :alt="type.label" :formats="['webp']" loading="lazy" :src="type.image" />
          </Column>
          <Column class="content" :sm="7">
            <h3>{{ type.label }}</h3>
            <p>{{ type.text }}</p>
            <div class="cta">
              <a
                v-if="type.link.href"
                v-bind="type.link"
                class="Btn sm inv"
                :title="type.cta">
                {{ type.cta }}
              </a>
              <Route
                v-else
                v-bind="type.link"
                class="Btn sm inv"
                :title="type.cta">
                {{ type.cta }}
              </Route>
            </div>
          </Column>
        </Row>
      </div>
    </Wrap>
  </section>
</template>

<script>
import { getters, mergeLabels } from '../../../utils'

export default {
  data() {
    return {
      images: [
        {
          image: '../../../../assets/solar-for-everyone/saules-energijos-generavimas-ant-stogo.jpg',
          link: {
            to: 'SolarPlants',
          },
        },
        {
          image: '../../../../assets/solar-for-everyone/saules-elektriniu-parkas.jpg',
          link: {
            href: 'https://saulesbendruomene.lt',
            target: '_blank',
            rel: 'noopener',
          },
        },
        {
          image: '../../../../assets/solar-for-everyone/ev-stotele.jpg',
          link: { to: { name: 'SolarPlantsB2b', hash: '#FormContactB2b' } },
        },
      ],
    }
  },
  computed: {
    types() {
      return mergeLabels(this.images, this.$ta('items'))
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionSolarForEveryone {
  background-color: $grey-lightest;
  padding-top: 3rem;
  @include md {
    padding-top: 5rem;
  }

  h2 {
    font-size: $h3;
    margin-bottom: 3rem;
    text-align: center;
    @include sm {
      margin-bottom: 5rem;
    }
  }

  .Wrap.container {
    max-width: 32rem;
    padding: 0;
    @include sm {
      max-width: 55rem;
    }
  }

  .plant-type {
    margin-bottom: 2rem;

    .image {
      overflow: hidden;

      img {
        max-width: 100%;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      @include sm {
        padding-left: 2rem;
      }

      h3 {
        font-size: $h35;
        margin-bottom: 1rem;
        margin-top: 1rem;
        @include sm {
          margin-top: 0;
        }
      }

      .cta {
        align-items: flex-end;
        display: flex;
        flex-grow: 1;
        margin-top: 2rem;
        @include sm {
          justify-content: flex-end;
        }
      }
    }
  }
}
</style>

// {
//   "label": "Integruota į stogą",
//   "text": "Galite rinktis saulės elektrinę, tarnaujančią vietoje įprastos stogo dangos. Tai saugus sprendimas, skirtas estetiškam stogo ploto išnaudojimui ir energijos generavimo efektyvumui garantuoti."
// },
// {
//   "label": "Ant fasado",
//   "text": "Į fasadą integruota saulės elektrinė yra šiuolaikiškas būdas generuoti atsinaujinančią energiją tuo pačiu apsaugant pastatą nuo lietaus, sniego ir vėjo poveikio. Saulės elektrinėmis dengto fasado ar kitų architektūrinių elementų nebereikės perdažyti ir kitaip prižiūrėti."
// }
<i18n>
{
  "lt": {
    "title": "Tvarios energijos sprendimai kiekvienam",
    "items": [
      {
        "label": "Saulės elektrinė ant namo stogo",
        "text": "„Nogrid“ ekspertai įrengė šimtus saulės elektrinių ant individualių namų su šlaitiniais ir plokščiais stogais. Padedame savo klientams išsirinkti efektyvius ir saugius sprendimus bei suteikiame visą reikalingą informaciją apie tai, kaip eksploatuoti ir prižiūrėti saulės elektrinę, kad ši tarnautų kelias dešimtis metų ir maksimaliu našumu.",
        "cta": "Daugiau informacijos"
      },
      {
        "label": "Nutolusi saulės elektrinė",
        "text": "Kad pasinaudotumėte nutolusių saulės elektrinių teikiama nauda, neprivalote būti nuosavo sklypo savininku. Dabar galite naudoti saulės elektrinės energiją iš geografiškai nutolusios vietos. Tai ypač patogu daugiabučių namų gyventojams.",
        "cta": "Daugiau informacijos"
      },
      {
        "label": "Elektromobilių įkrovimo stotelė namams",
        "text": "Namuose įsirengę nuosavą saulės elektrinę, jos pagamintą elektros energiją galite panaudoti ir elektromobilio krovimui bei taip dar labiau susimažinti savo išlaidas elektrai. „Nogrid“ teikia šių stotelių įrengimo paslaugas savo klientams bei konsultuoja susijusiais klausimais.",
        "cta": "Gauti pasiūlymą"
      }
    ]
  }
}
</i18n>
