<template>
  <section id="SectionPartners">
    <Wrap>
      <h2 v-if="doShowTitle">
        {{ $t('title') }}
      </h2>
      <Row class="partners">
        <Column
          v-for="partner in partners"
          :key="partner.id"
          class="partner-item"
          :md="3"
          :sm="6">
          <!-- <Component
            :is="partner.portfolio ? 'Route' : 'span'"
            :id="partner.portfolio ? partner.portfolio.id : null"
            to="PortfolioItem"> -->
          <a
            :alt="partner.name"
            :aria-disabled="!partner.link"
            :href="partner.link"
            target="_blank">
            <Pic
              :alt="partner.name || $t('partnerAltFallback')"
              :src="getPartnerImage(partner)" />
          </a>
          <!-- </Component> -->
        </Column>
      </Row>
      <PaginationShowMore
        v-if="partnersCount > limitPartners"
        :limit.sync="limitPartners"
        :total="partnersCount" />
    </Wrap>
  </section>
</template>

<script>
import { actions, getters } from 'views/utils'
import PaginationShowMore from 'views/components/PaginationShowMore'

export default {
  components: { PaginationShowMore },
  props: {
    doShowTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      limitPartners: 12,
    }
  },
  computed: {
    partners() {
      return this.query('partner', {
        $limit: this.limitPartners,
        $sort: { id: -1 },
      })
    },
    partnersCount() {
      return this.query('partner').length
    },
    ...getters('getPartnerImage'),
  },
  methods: {
    ...actions('FETCH_PARTNER'),
  },
  created() {
    this.LOAD(this.FETCH_PARTNER())
  },
}
</script>

<style lang="scss">
#SectionPartners {
  background-color: $white;
  padding-bottom: 4rem;
  padding-top: 4rem;

  .Row.partners {
    justify-content: center;
  }

  .partner-item {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  h2 {
    font-size: $h3;
    margin-bottom: 3rem;
    text-align: center;
    @include sm {
      margin-bottom: 5rem;
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "title": "Mūsų partneriai – rinkoje pirmaujantys prekių ženklai",
    "partnerAltFallback": "Mūsų partneris"
  }
}
</i18n>
