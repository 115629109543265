<template>
  <section id="LatestBlogPosts">
    <Wrap>
      <h2>{{ $t('title') }}</h2>
      <WrapScroll>
        <Column v-for="post in posts" :key="post.id" class="item" :lg="5">
          <CardBlogSmall :post="post" />
        </Column>
        <template #button>
          <Route class="Btn sm inv" to="Blog">
            {{ $t('all') }}
          </Route>
        </template>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardBlogSmall from './CardBlogSmall'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from 'views/utils'

export default {
  components: { CardBlogSmall, WrapScroll },
  computed: {
    posts() {
      return this.query('blog', {
        $limit: 4,
        $sort: { id: -1 },
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#LatestBlogPosts {
  padding-bottom: 4rem;

  h2 {
    margin-bottom: 2rem;
    @include md {
      margin-bottom: 4rem;
    }
  }

  .CardBlogSmall {
    white-space: normal;
  }
}
</style>

<i18n>
{
  "lt": {
    "all": "Daugiau straipsnių",
    "title": "Tinklaraštis"
  }
}
</i18n>
