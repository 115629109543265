<template>
  <div class="Feedbacks">
    <div class="quote">
      "
    </div>
    <Wrap md>
      <Row class="feedbackWrap">
        <Column class="chevron" :xs="1">
          <div @click="changeFeedback('prev')" @keypress.enter="changeFeedback('prev')">
            <Icon name="chevron-left" />
          </div>
        </Column>
        <Column :xs="10">
          <div :key="currentFeedbackId" class="feedback">
            <div v-if="isVideo" class="videoContainer">
              <a :href="currentFeedback.content" target="_BLANK">
                <div
                  class="play">
                  <Pic
                    alt="Žiūrėti video įrašą"
                    class="play-button"
                    src="../../../assets/video-play-bold.svg" />
                </div>
                <img alt="Video thumbnail" :src="currentFeedback.thumbnail">
              </a>
            </div>
            <div v-else class="text">
              „ {{ currentFeedback.content }} “
            </div>

            <div class="client">
              {{ currentFeedback.name }}, {{ currentFeedback.capacity }}
            </div>
          </div>
        </Column>
        <Column class="chevron" :xs="1">
          <div @click="changeFeedback('next')" @keypress.enter="changeFeedback('next')">
            <Icon name="chevron-right" />
          </div>
        </Column>
      </Row>
    </Wrap>
  </div>
</template>

<script>
import { getters } from '../utils'

export default {
  props: {
    feedbackItem: Object,
  },
  data() {
    return {
      timer: null,
      currentFeedbackId: 0,
      feedbacks: [
        {
          name: 'Birutė',
          capacity: '10 kW',
          contentType: 'youtube',
          content: 'https://www.youtube.com/watch?v=9QHXNS9dZPs',
          thumbnail: 'https://urbanwebstack-uploadspublicbucket-52in24jckklk.s3.amazonaws.com/feedback_thumb_birute_fs_03d3eb5fc0.jpeg',
          customTime: 10000,
        },
        {
          name: 'Alvydas',
          capacity: '7 kW',
          contentType: 'youtube',
          content: 'https://www.youtube.com/watch?v=mrLpZc8mYRQ',
          thumbnail: 'https://urbanwebstack-uploadspublicbucket-52in24jckklk.s3.amazonaws.com/feedback_thumb1_9b857a0d15.jpeg',
          customTime: 10000,
        },
        {
          name: 'Karolis',
          capacity: '15 kW',
          contentType: 'youtube',
          content: 'https://www.youtube.com/watch?v=wprdkV9QM08',
          thumbnail: 'https://urbanwebstack-uploadspublicbucket-52in24jckklk.s3.amazonaws.com/feedback_thumbnail2_1_77c0d02af6.jpeg',
          customTime: 10000,
        },
        {
          name: 'Artūras',
          capacity: '9,9 kW',
          contentType: 'text',
          content: this.$ta('feedbackTexts')[0],
        },
        {
          name: 'Virgis',
          capacity: '9,9 kW',
          contentType: 'text',
          content: this.$ta('feedbackTexts')[1],
        },
        {
          name: 'Kęstutis',
          capacity: '8,31 kW',
          contentType: 'text',
          content: this.$ta('feedbackTexts')[2],
        },
        {
          name: 'Edvinas',
          capacity: '9,9 kW',
          contentType: 'text',
          content: this.$ta('feedbackTexts')[3],
        },
        {
          name: 'Mantas',
          capacity: '7 kW',
          contentType: 'text',
          content: this.$ta('feedbackTexts')[4],
        },
      ],
    }
  },
  computed: {
    autoSwipeTime() {
      return this.currentFeedback.customTime || 5000
    },
    currentFeedback() {
      return this.feedbacks[this.currentFeedbackId]
    },
    isVideo() {
      const typesVideo = [
        'youtube',
      ]

      return typesVideo.includes(this.currentFeedback.contentType)
    },
    ...getters('screenSizes'),
  },
  methods: {
    autoChange() {
      this.changeFeedback('next')
    },
    changeFeedback(direction) {
      if (direction === 'next') {
        this.currentFeedbackId = this.currentFeedbackId < (this.feedbacks.length - 1)
          ? this.currentFeedbackId = this.currentFeedbackId + 1
          : this.currentFeedbackId = 0
      } else {
        this.currentFeedbackId = this.currentFeedbackId > 0
          ? this.currentFeedbackId = this.currentFeedbackId - 1
          : this.currentFeedbackId = (this.feedbacks.length - 1)
      }

      this.updateTimer()
    },
    updateTimer() {
      if (this.timer) clearInterval(this.timer)

      this.timer = setInterval(this.autoChange, this.autoSwipeTime)
    },
  },
  created() {
    this.updateTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style lang="scss">
.Feedbacks {
  align-items: center;
  background-color: $grey-lightest;
  display: flex;
  // max-height: 45vh;
  min-height: 20vh;
  position: relative;

  .feedbackWrap {
    align-items: center;
    margin: 2rem 0;
    min-height: 10rem;
  }

  .feedback {
    animation: fadeIn ease 3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
    }

    .text {
      font-size: 1.5rem;
      font-style: italic;
      text-align: center;
    }

    .videoContainer {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;

      > a {
        img {
          width: 20rem;
          @include md {
            width: 25rem;
          }
        }

        > .play {
          align-items: center;
          display: flex;
          height: 100%;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          .play-button img {
            @include trans;
            cursor: pointer;
            height: 60px;
            min-height: unset;
            width: 60px;
            @include md {
              width: auto;
            }
          }
        }
      }
    }

    .client {
      margin-top: 2rem;
      text-align: center;
    }
  }

  .chevron {
    color: $grey;
    font-size: 3rem;

    &:hover {
      color: $black;
      cursor: pointer;
    }
  }

  .quote {
    font-size: 6rem;
    font-style: italic;
    opacity: 0.2;
    position: absolute;
    top: -0.5rem;
    @include md {
      font-size: 15rem;
      top: -2rem;
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "feedbackTexts": [
      "Komanda tikrai išmano savo darbą, o maloniausia, kad į jį žiūri labai atsakingai.",
      "Šaunuoliai. Darbai atlikti be priekaištų!",
      "Puikiai atliktas darbas, malonus bendravimas su administracijas, montuotojais. Rekomenduoju!",
      "Jau rekomendavau ne vienam ir dar rekomenduosiu jei tik kas paklaus, nes darbai atlikti nepriekaištingai ir laiku",
      "Bendravau su dideliu ratu žmonių, visi iki vieno puikiai išmano savo drabą, yra labai paslaugūs ir geranoriški. Ačiū visai komandai ir sėkmės darbuose!"
    ]
  }
}
</i18n>
