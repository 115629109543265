<template>
  <Card class="CardBlogSmall">
    <div class="image">
      <Pic
        :alt="post.title"
        draggable="false"
        :src="getBlogImage(post)" />
    </div>
    <div class="body">
      <div class="date">
        {{ $d(new Date(post.created_at), 'short') }}
      </div>

      <Route class="title" :params="{ slug: post.slug }" :title="post.title" to="BlogItem">
        {{ post.title }}
      </Route>
      <div class="more">
        <Route class="Btn sm" :params="{ slug: post.slug }" :title="$t('more')" to="BlogItem">
          {{ $t('more') }}
        </Route>
      </div>
    </div>
  </Card>
</template>

<script>
import { getters } from '../../../utils'

export default {
  props: {
    post: Object,
  },
  computed: getters('getBlogImage'),
}
</script>

<style lang="scss">
.CardBlogSmall {
  > .image {
    height: 15rem;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
    }
  }

  > .body {
    height: 20rem;
    padding: 2rem;
    position: relative;

    .Btn {
      padding: 1rem 3rem;
    }

    .more {
      bottom: 2rem;
      position: absolute;
      right: 2rem;
    }

    > .meta {
      color: $text-color;

      > .date {
        color: $grey;
        display: inline-block;
        margin-left: 1rem;
        vertical-align: middle;
      }
    }

    > a.title {
      @include ellipsis(4);
      color: $text-color;
      font-size: $h4;
      line-height: 2rem;
      margin: 2rem 0 1rem;
      min-height: 6rem;
      @include lg {
        min-height: 3rem;
      }
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "more": "Daugiau"
  }
}
</i18n>
