<template>
  <section id="SectionPortfolios">
    <Wrap>
      <h2 class="title">
        {{ $t('title') }}
      </h2>
      <WrapScroll>
        <Column v-for="portfolio in portfolios" :key="portfolio.id" class="item" :lg="4">
          <CardPortfolioSmall isShortSubtitle :portfolio="portfolio" />
        </Column>
        <template #button>
          <Route class="Btn sm inv" :title="$t('all')" to="Portfolio">
            {{ $t('all') }}
          </Route>
        </template>
      </WrapScroll>
    </Wrap>
  </section>
</template>

<script>
import CardPortfolioSmall from './CardPortfolioSmall'
import WrapScroll from 'views/components/WrapScroll'
import { getters } from 'views/utils'

export default {
  components: { CardPortfolioSmall, WrapScroll },
  props: {
    isShortSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    portfolios() {
      return this.query('portfolio', {
        locale: this.locale,
        $limit: 8,
        $sort: { id: -1 },
      })
    },
    ...getters(),
  },
}
</script>

<style lang="scss">
#SectionPortfolios {
  padding-bottom: 4rem;

  .title {
    color: $grey;
  }

  h2 {
    margin-bottom: 2rem;
    @include md {
      margin-bottom: 4rem;
    }
  }

  .CardPortfolioSmall {
    white-space: normal;
  }
}
</style>

<i18n>
{
  "lt": {
    "all": "Visi projektai",
    "title": "Daugiau nei 2000+ įgyvendintų projektų"
  }
}
</i18n>
