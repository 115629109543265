<template>
  <SectionSplit
    id="SectionAboutUs"
    alt="Saulės elektrinės ant šlaitinio stogo"
    class="IndexSectionAboutUs"
    :headline="$t('headline')"
    :src="isDesktop ? '../../assets/landing/saules-elektrines-slaitinis-stogas.jpg' : null">
    <!-- ../../assets/landing/saules-elektrines-slaitinis-stogas.jpg -->
    <h2>
      {{ $t('title') }}
    </h2>
    <p>
      {{ $t('text') }}
    </p>
    <Route class="Btn" :title="$t('more')" :to="{ name: 'AboutUs' }">
      {{ $t('more') }}
    </Route>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'
import { getters } from '../../../utils'

export default {
  components: {
    SectionSplit,
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
.IndexSectionAboutUs {
  h2 {
    font-size: $h3;
    margin-bottom: 2rem;
  }
}
</style>

<i18n>
{
  "lt": {
    "text": "„Nogrid“ – inovatyvi, patikima ir kokybę vertinanti bendrovė, teikianti saulės elektrinių sprendimus privatiems namų ūkiams ir verslui. Veiklą pradėjusi 2016 metais, įmonė jau turi virš 2000+ patenkintų klientų tiek Lietuvoje, tiek užsienyje. Profesionali projektuotojų ir konsultantų komanda leidžia „Nogrid“ klientams gauti geriausios kokybės paslaugas visuose elektrinės įsigijimo etapuose nuo įrangos pasirinkimo iki jos sumontavimo. Įmonė tiekia Tier1 gamintojų įrangą ir savo klientams siūlo sprendimus, užtikrinančius maksimalų sutaupymą ir greitą atsipirkimą.",
    "headline": "Nogrid",
    "more": "Sužinoti daugiau",
    "title": "Saulės energijos lyderiai"
  }
}
</i18n>
