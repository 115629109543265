<template>
  <Card class="CardPortfolioSmall">
    <div class="image">
      <Pic
        :alt="portfolio.media.alternativeText"
        draggable="false"
        loading="lazy"
        :src="getPortfolioItemImage(portfolio)" />
    </div>
    <h3 class="title">
      {{ portfolio.title }}
    </h3>
    <div class="body">
      {{ subtitle }}
      <div />
    </div>
  </Card>
</template>

<script>
import { getters } from '../../../utils'

export default {
  props: {
    portfolio: Object,
    isShortSubtitle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    subtitle() {
      const capacity = this.$options.filters.commaDecimal(this.portfolio.capacity)
      const location = this.portfolio.location || ''
      return this.isShortSubtitle ? this.portfolio.location : `${capacity}kW saulės elektrinė ant skardinio stogo dangos, ${location}`
    },
    ...getters('getPortfolioItemImage'),
  },
}
</script>

<style lang="scss">
.CardPortfolioSmall {
  margin-bottom: 3rem;

  > .image {
    height: 15rem;
    overflow: hidden;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  > .title {
    font-size: 1.25rem;
    font-weight: $bold;
    padding: 2rem 2rem 0;
  }

  > .body {
    height: 6rem;
    padding: 1rem 2rem 2rem;
    position: relative;

    > a.title {
      @include ellipsis(4);
      color: $text-color;
      font-size: $h4;
      line-height: 2rem;
      margin: 2rem 0 1rem;
      min-height: 6rem;
      @include lg {
        min-height: 3rem;
      }
    }
  }
}
</style>
