<template>
  <div id="Index">
    <Hero
      :class="{ bigger: hasHeroForm }"
      :formats="['webp']"
      src="../assets/landing/ant-nuosavo-namo-saules-elektrine.jpg">
      <h1>
        {{ $t('title') }}
      </h1>
      <h4>
        {{ $t('subtitle') | lineBreak }}
      </h4>
    </Hero>
    <FormContactHero />
    <SectionAboutUs />

    <LazyHydrate whenVisible>
      <Feedbacks />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <SectionHappyClients />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <SectionSolarForEveryone />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <SectionWhySolar />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <Calculator />
    </LazyHydrate>
    <!-- <LazyHydrate whenVisible>
      <SectionSupport />
    </LazyHydrate> -->
    <LazyHydrate whenVisible>
      <SectionPortfolios isShortSubtitle />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <SectionFaq :faqs="faqs" />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <LatestBlogPosts />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <SectionPartners />
    </LazyHydrate>
    <LazyHydrate whenVisible>
      <FormNewsletter />
    </LazyHydrate>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
// import Calculator from 'views/components/Calculator'
import FormContactHero from 'views/components/FormContactHero'
import Hero from 'views/components/Hero'
import LazyHydrate from 'vue-lazy-hydration'
import Meta from 'views/mixins/Meta'
// import SectionPartners from 'views/components/SectionPartners'
// import SectionAboutUs from './components/SectionAboutUs'
// import SectionFaq from 'views/components/SectionFaq'
// import SectionPortfolios from './components/SectionPortfolios'
// import SectionSolarForEveryone from './components/SectionSolarForEveryone'
// import SectionSupport from './components/SectionSupport'
// import SectionWhySolar from './components/SectionWhySolar'
// import { formExperimentId } from '../../../config'

export default {
  mixins: [
    Meta(require('@/assets/social/homepage.jpg')),
  ],
  components: {
    Calculator: () => import(/* webpackChunkName: 'index' */'views/components/Calculator'),
    FormContactHero,
    Hero,
    LazyHydrate,
    SectionPartners: () => import(/* webpackChunkName: 'index' */'views/components/SectionPartners'),
    Feedbacks: () => import(/* webpackChunkName: 'index' */'views/components/Feedbacks'),
    SectionHappyClients: () => import(/* webpackChunkName: 'index' */'./components/SectionHappyClients'),
    SectionAboutUs: () => import(/* webpackChunkName: 'index' */'./components/SectionAboutUs'),
    SectionFaq: () => import(/* webpackChunkName: 'index' */'views/components/SectionFaq'),
    SectionPortfolios: () => import(/* webpackChunkName: 'index' */'./components/SectionPortfolios'),
    SectionSolarForEveryone: () => import(/* webpackChunkName: 'index' */'./components/SectionSolarForEveryone'),
    // SectionSupport: () => import(/* webpackChunkName: 'index' */'./components/SectionSupport'),
    SectionWhySolar: () => import(/* webpackChunkName: 'index' */'./components/SectionWhySolar'),
    LatestBlogPosts: () => import(/* webpackChunkName: 'index' */'./components/LatestBlogPosts'),
    FormNewsletter: () => import(/* webpackChunkName: 'index' */'./components/FormNewsletter'),
  },
  computed: {
    faqs() {
      return this.query('faq')
    },
    postCount() {
      return this.query('post').length
    },
    // heroFormCombination() {
    //   const experiment = this.getExperiment(formExperimentId)

    //   return experiment ? experiment.combination : '0'
    // },
    hasHeroForm() {
      return true
      // return this.heroFormCombination !== '0'
    },
    ...getters('getExperiment'),

  },
  methods: actions(
    'FETCH_BLOG',
    'FETCH_FAQ',
    'FETCH_PORTFOLIO',
  ),
  created() {
    this.LOAD(Promise.all([
      this.FETCH_BLOG(),
      this.FETCH_FAQ(),
      this.FETCH_PORTFOLIO(),
    ]))
  },
  mounted() {
    this.$analytics.fbq.event('ViewContent')
  },
}
</script>

<style lang="scss">
$zoom: 1.25;
$zoomBigger: 1.5;

#Index {
  .Hero {
    padding-top: 2rem;

    h1 {
      margin: 0 auto 5rem 0;
      max-width: 45rem;
      text-align: left;
    }

    p {
      font-size: $h4;
      margin-bottom: 2rem;

      a {
        color: $white;

        .Icon {
          display: inline-block;
          transform: translateY(2px);
        }
      }
    }

    img {
      filter: brightness(0.65);
    }

    &.bigger {
      @include lg {
        min-height: 70vh;

        h4 {
          max-width: 60vw;
        }
      }
      @include xl {
        min-height: 65vh;
      }
      @include xxl {
        min-height: 75vh;
      }
      @media (-webkit-min-device-pixel-ratio: $zoom) {
        @include lg {
          min-height: 85vh;
        }
      }
      @media (-webkit-min-device-pixel-ratio: $zoomBigger) {
        @include lg {
          min-height: 95vh;
        }
      }
    }
  }

  #SectionAboutUs,
  #SectionSupport {
    background-color: $white;

    .Btn {
      margin-top: 1.5rem;
    }
  }

  #FormNewsletter {
    background-color: $grey-lightest;
  }

}
</style>

<i18n>
{
  "lt": {
    "meta": {
      "title": {
        "complement": "saulės elektrinių ekspertai",
        "inner": "Nogrid",
        "separator": "-"
      }
    },
    "solution": "Saulės energija jūsų verslo plėtrai",
    "solutionSlugs": {
      "business": "sprendimai-verslui",
      "investors": "sprendimai-investuotojams"
    },
    "title": "Nogrid – efektyvių saulės elektrinių ekspertai",
    "subtitle": "Kasmet šimtams klientų padedame tapti tvarios energijos gamintojais, kurti žalesnę aplinką ir taupyti"
  }
}
</i18n>
