<template>
  <SectionIconList
    align="center"
    :items="reasons"
    :itemsPerLine="3"
    :size="null"
    :title="$t('headline')" />
</template>

<script>
import SectionIconList from 'views/components/SectionIconList'
import { mergeLabels } from 'views/utils'

export default {
  components: { SectionIconList },
  data() {
    return {
      perksValues: [
        { icon: 'arrows-up-down' },
        { icon: 'wallet' },
        { icon: 'tree' },
        { icon: 'like' },
        { icon: 'government' },
      ],
    }
  },
  computed: {
    reasons() {
      return mergeLabels(this.perksValues, this.$ta('reasons'))
    },
  },
}
</script>

<style lang="scss">
</style>

<i18n>
{
  "en": {
    "headline": "Why solar?",
    "reasons": []
  },
  "lt": {
    "headline": "Įsigyti verta",
    "reasons": [
      {
        "title": "Su parama atsiperka per 3-4 metus",
        "content": "Su APVA parama, saulės elektrinė atsipirks per 3-4 metus, skaičiuojant su 30 ct/kW įkainiu. Be paramos, investicija gyventojams atsipirks per maždaug 4-5 metus."
      },
      {
        "title": "70% MAŽESNĖ ELEKTROS SĄSKAITA",
        "content": "Įsigiję saulės elektrinę tapsite tvarios energijos gamintojais ir sumažinsite savo elektros sąskaitą iki 70%."
      },
      {
        "title": "Mažesnė tarša",
        "content": "10 kW saulės elektrinė mažina taršą iki 5 tonų CO2 per metus. Gamindami saulės energiją saugote aplinką ir švarinate orą."
      },
      {
        "title": "Ilgalaikė nauda jums",
        "content": "Saulės elektrinė užtikrina didesnę Jūsų turto vertę ir padeda išvengti kylančių elektros kainų."
      },
      {
        "title": "Galimybė gauti valstybės paramą",
        "content": "Valstybė ir Europos Sąjunga kasmet skiria lėšų atsinaujinančios energetikos plėtrai. Pasinaudoti parama ir pagreitinti saulės elektrinės atsipirkimą galite ir Jūs."
      }
    ]
  }
}
</i18n>
