<template>
  <div class="FormContactHero" :class="{ white: leadFormName === 'hero-form-white' }">
    <div class="titleBar">
      <div class="title">
        Užpildykite užklausos formą ir mūsų ekspertas su jumis susisieks per 1 d.d.!
      </div>
    </div>
    <Wrap class="md">
      <div class="content">
        <FormValidation
          v-if="!hasSent"
          novalidate
          @submit="send">
          <Row>
            <Column>
              <Field
                autocomplete="fname"
                :label="$t('name')"
                required
                :value.sync="contact.firstName" />
            </Column>
            <Column>
              <Field
                :label="$t('monthFee')"
                :maxlength="14"
                required
                :value.sync="contact.monthFee" />
            </Column>
            <Column>
              <Field
                autocomplete="email"
                :label="$t('email')"
                required
                rules="email"
                type="email"
                :value.sync="contact.email" />
            </Column>
            <Column>
              <div class="phoneFields">
                <Field
                  autocomplete="tel-country-code"
                  class="countryCode"
                  disabled
                  value="+370" />
                <Field
                  autocomplete="tel"
                  class="phoneNumber"
                  :label="$t('phone')"
                  :maxlength="14"
                  :minlength="8"
                  required
                  rules="phone"
                  :value.sync="phoneWithoutCode" />
              </div>
            </Column>
            <Column>
              <Field
                class="apva"
                :label="$t('apva.question')"
                :options="apvaOptions"
                required
                type="select"
                :value.sync="contact.hasApva" />
            </Column>
            <Column>
              <Field
                class="comments"
                :label="$t('comments')"
                type="textarea"
                :value.sync="contact.message" />
            </Column>
          </Row>
          <div class="checkboxes">
            <Row>
              <Column>
                <!-- <NoticeRecaptcha /> -->
                <FieldConsent :value.sync="contact.hasAcceptedPolicy" />
                <FieldSubscribe :value.sync="contact.hasSubscribed" />
              </Column>
              <Column class="submit-column">
                <Btn class="sm" :isLoading="isSending" type="submit">
                  {{ $t('send') }}
                </Btn>
              </Column>
            </Row>
          </div>
        </FormValidation>
        <Notice v-if="hasSent && message" :class="message.theme">
          {{ message.text }}
        </Notice>
      </div>
    </Wrap>
  </div>
</template>

<script>
import { actions, getters } from 'views/utils'
import FieldSubscribe from './FieldSubscribe'
import Message from 'views/mixins/Message'
import { isProduction } from '../../config'
import { softThrow } from '../../bugsnag'

export default {
  mixins: [Message],
  components: { FieldSubscribe },
  // props: {
  //   formCombination: String,
  // },
  data() {
    return {
      phoneWithoutCode: '',
      contact: this.$store.getters.contactFactory(),
      isSending: false,
      hasSent: false,
    }
  },
  computed: {
    apvaOptions() {
      const labels = this.$ta('apva.options')
      return [true, false].map((value, index) => [value, labels[index]])
    },
    leadFormName() {
      return 'hero-form-white'
      // return this.formCombination === '1' ? 'hero-form-blue' : 'hero-form-white'
    },
    ...getters('isDesktop', 'referrers'),
  },
  methods: {
    async send() {
      try {
        this.isSending = true

        if (isProduction) {
          this.contact.phone = `+370${this.phoneWithoutCode}`
          const recaptcha = await this.$recaptchaToken('contact')
          await this.CREATE_CONTACT({
            ...this.contact,
            leadFormName: this.leadFormName,
            metaReferrers: this.referrers,
            timezone: ((new Date()).getTimezoneOffset() / 60),
            recaptcha,
          })
        }

        // this.$gtag.event('Siųsti', {
        //   event_category: 'Forma',
        //   event_label: 'PRIVATIEMS',
        // })
        this.$gtag.event('success', {
          phoneNumber: this.contact.phone,
          emailAddress: this.contact.email,
        })

        this.$analytics.fbq.event('Lead')

        this.hasSent = true
        this.success(this.$t('success'))
      } catch (error) {
        error.body = this.contact
        softThrow(error)
      } finally {
        this.isSending = false
      }
    },
    ...actions('CREATE_CONTACT'),
  },
}
</script>

<style lang="scss">
$form-roundness: 10px;

.FormContactHero {
  background-color: $main;
  color: $main;
  position: relative;
  @include md {
    position: relative;
  }
  @include lg {
    border-radius: $form-roundness;
    box-shadow: $shadow-lg;
    margin: 3rem 0 0;
    position: absolute;
    right: 2rem;
    top: 4.5rem;
    width: 22rem;
    z-index: 2;
  }
  @include xl {
    margin: 1rem 0 0;
  }
  @include xxl {
    margin: 3rem 0 0;
  }

  .titleBar {
    align-items: center;
    background-color: $main;
    display: flex;
    height: 3.5rem;
    justify-content: center;
    margin-top: 1rem;
    @include lg {
      border-top-left-radius: $form-roundness;
      border-top-right-radius: $form-roundness;
      margin-top: unset;
    }

    .title {
      color: $white;
      font-weight: $bold;
      text-align: center;
      width: 90%;
    }
  }

  .content {
    padding: 0 0.5rem;
  }

  a {
    border-bottom: 1px solid $grey-lightest;
    color: $white;

    &:hover {
      border-color: $white;
    }
  }

  span {
    color: $white;
  }

  h3 {
    margin-bottom: 4rem;
  }

  textarea {
    min-height: 5px;
  }

  .checkboxes {
    margin-top: 0.5rem;
    text-align: left;
    @include md {
      margin-top: 0;
    }
  }

  form {
    // padding-top: 2.5rem;
    padding: 5rem 3rem 0;
    @include md {
      padding: 5rem 7rem;
    }
    @include lg {
      padding: 2.5rem 0 0;
    }

    .phoneFields {
      display: flex;

      > .countryCode {
        min-width: 4rem;
        width: 4rem;

        input {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          text-align: right;
        }
      }

      > .phoneNumber {
        flex-grow: 1;

        label {
          left: -4rem;
          position: absolute;
        }

        input {
          border-bottom-left-radius: 0;
          border-left: 1px solid;
          border-top-left-radius: 0;
        }
      }
    }

    .Field {
      // margin-bottom: 3rem;
      line-height: 0.8rem;

      // input select {
      // border: 1px solid $grey;
      // }

      &.type-select {
        border: none;

        .Icon {
          padding: 0.55rem 0.5rem;
        }

        select {
          border: 1px solid $grey;
          line-height: 1.2rem;
        }
      }
    }

    .Field-errors {
      font-size: 0.6rem;
      margin-top: 0.2rem;
      position: absolute;
    }

    .Field-cont {
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 2.7rem;

      .required {
        color: $text-color;
      }

      &.comments {
        margin-bottom: 1.5rem;
      }
    }

    label {
      color: $white;
      font-size: 0.9rem;
      font-weight: $semibold;
      left: 0;
      top: -1.3rem;
    }

    input,
    select,
    textarea {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 5px;
      text-align: left;
      text-align-last: left;
    }

    .checkboxes {
      text-align: left;

      .Field-cont {
        font-size: 0.5rem;
        line-height: 1.2rem;
        margin-bottom: 1.3rem;
        @include lg {
          input {
            height: 0.7rem;
          }

          label {
            font-size: 0.7rem;
            line-height: 1rem;
          }
        }

        .Field-errors {
          margin-top: 0;
        }
      }

      //       .FieldConsent {
      //   font-size: 0.5rem;
      //   margin-bottom: 1rem;
      // }

      // .FieldSubscribe {
      //   font-size: 0.5rem;
      //   margin-bottom: 1rem;
      // }
    }

    .submit-column {
      margin-bottom: 1rem;
      text-align: center;

      .Btn {
        background-color: $text-color;
        color: $white;
        width: 80%;

        &:hover {
          background-color: $grey-darker;
          border-color: $white;
        }
      }

      // @include md {
      //   text-align: right;
      // }
    }

    // .submit {
    //   background: $white;
    //   color: $main;
    //   max-width: 20rem;
    //   min-width: 9rem;
    //   width: 100%;

    //   &:hover {
    //     background: $grey-lighter;
    //   }
    // }
  }

  // .NoticeRecaptcha {
  //   margin: 0 0 2rem;

  //   a {
  //     color: $white;
  //     text-decoration: underline;
  //   }
  // }

  .Notice {
    margin-top: 2rem;
  }

  &.white {
    background-color: $white;
    @include lg {
      // stylelint-disable-next-line plugin/stylelint-no-indistinguishable-colors
      box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2);
    }

    input {
      border: 1px solid $text-color;
    }

    a, label, span {
      color: $text-color;
    }

    a {
      border-bottom: 1px solid $text-color;
    }

    .phoneFields {
      .phoneNumber {
        input {
          border-left: none;
        }
      }
    }

    .submit-column {
      margin-bottom: 1rem;
      text-align: center;

      .Btn {
        background-color: $main;
        color: $white;
        width: 80%;

        &:hover {
          background-color: $main-lightish;
          // border-color: $grey;
        }
      }

    }

  }
}

</style>

<i18n>
{
  "lt": {
    "comments": "Komentarai",
    "email": "El. paštas",
    "name": "Vardas",
    "phone": "Telefono nr.",
    "apva": {
      "question": "Ar gavote APVA paramą?",
      "options": [
        "Taip",
         "Ne"
      ]
    },
    "monthFee": "Kiek per mėnesį sumokate už elektrą?",
    "send": "Gaukite pasiūlymą",
    "success": "Dėkojame! Netrukus su jumis susisieksime."
  }
}
</i18n>
