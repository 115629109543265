<template>
  <div class="WrapScroll">
    <div
      class="wrapper"
      :class="{
        dragging: isClickDisabled,
        end: scrolled === scrollMax,
        start: !scrolled,
      }">
      <div
        ref="content"
        class="wrapper-content"
        @blur="endDrag"
        @dragend="endDrag"
        @mousedown="startDrag"
        @mouseleave="endDrag"
        @mousemove="drag"
        @mouseup="endDrag">
        <slot />
      </div>
    </div>
    <div class="scrollbar-row">
      <div ref="scrollbar" class="scrollbar" @scroll.passive="sync">
        <div class="scrollbar-inner" />
      </div>
      <div class="button-more">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
import { actions } from '../utils'

export default {
  data() {
    return {
      dragStart: 0,
      dragAmount: 0,
      isDragging: false,
      isClickDisabled: false,
      scrolled: 0,
      scrollMax: 0,
    }
  },
  methods: {
    ...actions('POST_LOAD'),
    startDrag(event) {
      this.isDragging = true
      this.dragStart = event.pageX - this.$refs.content.offsetLeft
      this.dragAmount = this.$refs.content.scrollLeft
    },
    drag(event) {
      if (!this.isDragging) return

      event.preventDefault()

      const dragPosition = event.pageX - this.$refs.content.offsetLeft
      const dragChange = (dragPosition - this.dragStart)

      if (!this.isClickDisabled && Math.abs(dragChange) > 10) {
        this.isClickDisabled = true
      }

      this.$refs.scrollbar.scrollLeft = this.dragAmount - dragChange
      this.sync()
    },
    endDrag(event) {
      this.isDragging = false
      this.isClickDisabled = false
      event.preventDefault()
    },
    setScrollWidth() {
      const { content, scrollbar } = this.$refs
      const { scrollWidth, clientWidth } = content
      this.scrollMax = (scrollWidth - clientWidth)
      scrollbar.children[0].style.width = `${this.scrollMax + scrollbar.clientWidth}px`
    },
    sync() {
      this.$refs.content.scrollLeft = this.$refs.scrollbar.scrollLeft
      this.scrolled = this.$refs.scrollbar.scrollLeft
    },
  },
  mounted() {
    this.$nextTick(this.setScrollWidth)
    this.POST_LOAD(this.setScrollWidth)
  },
  updated() {
    this.$nextTick(this.setScrollWidth)
  },
}
</script>

<style lang="scss">
$_background-color: rgba($white-ter, 0.8);
$_scrollbar-background: $grey-lighter;
$_scrollbar-color: $main;

.WrapScroll {
  .wrapper {
    margin: 0 -1rem;
    position: relative;

    &::before, &::after {
      @include trans(0.5s);
      bottom: 0;
      content: ' ';
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 4rem;
      z-index: 2;
      @include md {
        width: 16rem;
      }
    }

    &::before {
      background: linear-gradient(90deg, $_background-color, transparent);
      left: 0;
    }

    &::after {
      background: linear-gradient(90deg, transparent, $_background-color);
      right: 0;
    }

    > .wrapper-content {
      background-color: $_background-color;
      display: flex;
      overflow-x: scroll;
      overflow-y: hidden;
      @include md {
        overflow: hidden;
      }
    }

    &.dragging {
      cursor: grabbing;

      a {
        pointer-events: none;
      }
    }

    &.start {
      &::before {
        opacity: 0;
      }
    }

    &.end {
      &::after {
        opacity: 0;
      }
    }
  }

  .scrollbar-row {
    margin-top: 1.5rem;
    text-align: right;
    @include md {
      align-items: center;
      display: flex;
      justify-items: right;
      white-space: nowrap;
    }
  }

  .scrollbar {
    background-color: transparent;
    display: none;
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    scrollbar-color: $_scrollbar-color $_scrollbar-background;
    scrollbar-width: thin;
    width: 100%;
    z-index: 3;
    @include md {
      display: block;
    }

    .scrollbar-inner {
      font-size: 1px;
      height: 1px;
      line-height: 1px;
      visibility: hidden;
    }

    &::-webkit-scrollbar {
      background-color: $_scrollbar-background;
      height: 0.4rem;
    }

    &::-webkit-scrollbar-thumb {
      background: $_scrollbar-color;
      border-radius: $radius;
      height: 0.4rem;
    }
  }

  .button-more {
    margin-left: 1rem;
    @include md {
      margin-left: 2rem;
    }
  }
}
</style>
