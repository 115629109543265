<template>
  <section
    class="SectionHappyClients">
    <Splitter :left="5" :right="7">
      <div slot="left" class="percentage">
        94%
        <div class="percent-sub">
          Rekomenduoja
        </div>
      </div>
      <div slot="right" class="body">
        <h2>
          {{ $t('title') }}
        </h2>
        <p>
          {{ $t('text') }}
        </p>
      </div>
    </Splitter>
  </section>
</template>

<script>
import Splitter from 'views/components/Splitter'
import { getters } from '../../../utils'

export default {
  components: {
    Splitter,
  },
  computed: getters('isDesktop'),
}
</script>

<style lang="scss">
.SectionHappyClients {
  background-color: $white;

  h2 {
    font-size: $h3;
    margin-bottom: 2rem;
  }

  .percentage {
    align-items: center;
    color: $main;
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    font-weight: $bold;
    height: 100%;
    justify-content: center;
    line-height: 0.8;
    @include md {
      font-size: 10rem;
    }

    .percent-sub {
      display: block;
      font-size: 1.5rem;
      @include md {
        font-size: 2.5rem;
      }
    }
  }
}
</style>

<i18n>
{
  "lt": {
    "text": "Geriausias mūsų darbo įvertinimas - darbų kokybe bei rezultatu patenkinti klientai. Net 94% apklaustųjų atsakė, jog džiaugiasi suteiktomis paslaugomis, o bendradarbiavimą su Nogrid rekomenduotų ir draugui.",
    "title": "94% mūsų apklaustų klientų rekomenduoja rinktis Nogrid"
  }
}
</i18n>
